














































































































import { defineComponent } from "@vue/composition-api";
import { toUserDateFormat } from "@/apps/core/modules/utils/datetime";

export default defineComponent({
  name: "PerawatanListBox",
  components: {
    Avatar: () => import("@/apps/core/components/Avatar.vue"),
    DiagnosisTag: () => import("@/apps/penyakit/components/DiagnosisTag.vue"),
  },
  props: {
    perawatan: { type: Object, required: false },
  },
  setup(props) {
    const PICS_LIMIT = 3;
    let diagCountStr = "";
    let dpjpCountStr = "";
    let ppdsCountStr = "";
    let sortedDiagnosisList: Array<string> = [];
    let dpjpArr: Array<object> = [];
    let ppdsArr: Array<object> = [];

    if (props.perawatan) {
      // Ordering untuk diagnosisList
      sortedDiagnosisList = props.perawatan.diagnosisList.sort(
        (el1: Record<string, any>, el2: Record<string, any>) => {
          if (el1.utama && !el2.utama) return -1;
          if (!el1.utama && el2.utama) return 1;
          if (el1.diagnosisNama > el2.diagnosisNama) return 1;
          if (el1.diagnosisNama < el2.diagnosisNama) return -1;
          return 0;
        }
      );

      const diagLength = props.perawatan.diagnosisList.length;
      diagCountStr = diagLength > 0 ? `(${diagLength})` : "";
      const dpjpIdArr: Array<string> = [];
      const ppdsIdArr: Array<string> = [];
      const dpjpSet = new Set(); // menggunakan set untuk menghilangkan duplikat
      const ppdsSet = new Set();
      const aktivitasList = props.perawatan.aktivitasList;
      for (const aktivitas of aktivitasList) {
        if (!dpjpIdArr.includes(aktivitas.dpjp.id)) {
          dpjpSet.add(aktivitas.dpjp);
          dpjpIdArr.push(aktivitas.dpjp.id);
        }
        if (!ppdsIdArr.includes(aktivitas.residen.id)) {
          ppdsSet.add(aktivitas.residen);
          ppdsIdArr.push(aktivitas.residen.id);
        }
      }
      dpjpCountStr = dpjpSet.size > 0 ? `(${dpjpSet.size})` : "";
      ppdsCountStr = ppdsSet.size > 0 ? `(${ppdsSet.size})` : "";

      dpjpArr = Array.from(dpjpSet).slice(0, PICS_LIMIT) as Array<object>;
      ppdsArr = Array.from(ppdsSet).slice(0, PICS_LIMIT) as Array<object>;
    }

    return {
      // Data
      detailUrl: "/perawatan",
      diagCountStr,
      dpjpArr,
      dpjpCountStr,
      PICS_LIMIT,
      ppdsArr,
      ppdsCountStr,
      sortedDiagnosisList,

      // Method
      toUserDateFormat,
    };
  },
});
